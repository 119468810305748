<template>
  <section class="pad-y-60 pad-b-30 text-center">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="title font-secondary fs-40 text-teal-dark">Om Swastyastu</h2>
      </div>

      <p
        class="font-weight-lighter mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa / Tuhan Yang
        Maha Esa, kami bermaksud menyelenggarakan acara Mepandes (Potong Gigi)
        putra-putri kami pada :
      </p>

      <div class="row">
        <div
          class="col-6 col-md-4 text-center mar-bottom-25"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/calendar-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              HARI / TANGGAL
            </div>
            <div class="font-weight-bold fs-18">
              {{ $moment($parent.time.start).format("dddd, DD MMMM YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="col-6 col-md-4 text-center mar-bottom-25"
          data-aos="fade-down"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/clock-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              WAKTU
            </div>
            <div class="font-weight-bold fs-18">
              {{ $moment($parent.time.start).format("HH.mm") }} -
              {{ $parent.time.end }}
            </div>
          </div>
        </div>
        <div
          class="col-md-4 text-center mar-bottom-25"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <div>
            <img
              src="@/assets/icons/marker-2.svg"
              style="width: 100%; max-height: 50px"
            />
            <div class="text-uppercase text-muted font-weight-lighter mt-3">
              Lokasi
            </div>
            <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
          </div>
        </div>
      </div>

      <slot> </slot>

      <p class="font-weight-lighter" data-aos="fade-left" data-aos-delay="300">
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada putra-putri
        kami. Mohon untuk <b>menggunakan masker</b> dan mematuhi
        <b>protokol kesehatan yang berlaku</b>. Atas kehadiran serta doa restu
        Bapak/Ibu/Saudara/i, kami sekeluarga mengucapkan terima kasih. Kami yang
        berbahagia.
      </p>

      <div
        style="letter-spacing: 3px"
        class="text-uppercase mar-top-25"
        data-aos="fade-up"
        data-aos-delay="400"
      >
        Keluarga
      </div>
      <h6 class="font-weight-bold mb-0" data-aos="fade-up" data-aos-delay="500">
        I Made Suparka (alm) & Ni Wayan Eli Suciati
      </h6>
      <p
        class="font-secondary text-muted"
        data-aos="fade-up"
        data-aos-delay="550"
      >
        Om Santhi Santhi Santhi Om
      </p>
    </div>
  </section>
</template>

<script>
import AGWPawiwahan from "@/components/Pawiwahan";

export default {
  components: {
    AGWPawiwahan,
  },
};
</script>